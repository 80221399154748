import Accounts from 'assets/icons/accounts';
import Bell from 'assets/icons/bell';
import Book from 'assets/icons/book';
import Cards from 'assets/icons/cards';
import Lifestyle from 'assets/icons/lifestyle';
import Menu from 'assets/icons/menu';
import Refill from 'assets/icons/refill';
import Settings from 'assets/icons/settings';
import Status from 'assets/icons/status.png';
import Transactions from 'assets/icons/transactions';
import Vault from 'assets/icons/vault';
import FullLogo from 'assets/logo/logo.png';
import Avatar from 'components/avatar';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from 'lib/utils';
import { ChevronRight, X } from 'lucide-react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import {
  ACCOUNT_DETAILS_ROUTE,
  ACCOUNTS_ROUTE,
  CARDS_ROUTE,
  REFILL_ROUTE,
  SETTINGS_ROUTE,
  TRANSACTIONS_ROUTE,
  VAULT_ROUTE,
} from 'routes/path';
import { useGetAccount } from 'services/account';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setComingSoonModalOpen,
  setMobileSidebarIsCollapsed,
} from 'store/slices/layout/layoutSlice';
import { truncateText } from 'utils/numberFormat';

import { MobileSidebarLink } from './sidebarLink';

const NavigationBar = () => {
  const { mobileSideBarIsCollapsed } = useAppSelector((state) => state.layout);

  const dispatch = useAppDispatch();
  const openNavigation = () => {
    dispatch(setMobileSidebarIsCollapsed(false));
  };

  const nav = useNavigate();
  const navigate = (to: string) => {
    dispatch(setMobileSidebarIsCollapsed(true));
    nav(to);
  };

  const { data, isPending } = useGetAccount();

  const firstName = data?.data.first_name;
  const lastName = data?.data.last_name;
  const fullName = `${firstName} ${lastName}`;

  const closeNavigation = () => {
    dispatch(setMobileSidebarIsCollapsed(true));
  };

  return (
    <div className="bg-[#FCFCFC] w-full sticky top-0 z-10">
      <div
        className={cn('flex justify-between items-center pt-6 pb-3 px-4 xl:px-0 xl:mx-[4.5rem]')}
      >
        <div className="hidden xl:flex justify-center w-full">
          <div className="w-full max-w-[43.125rem] h-14 flex items-center grid-cols px-4 bg-white rounded-lg shadow-sm focus-within:ring-2 focus-within:ring-platnova-purple">
            <div>
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#000000B2"
                strokeOpacity="0.7"
                strokeWidth="0.01px"
              >
                <path
                  d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
                  fill="currentColor"
                  fillRule="evenodd"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              className="h-12 rounded-md border border-transparent px-3 py-1 text-sm placeholder:text- bg-transparent w-full focus:ring-0 focus-visible:outline-none focus-visible:ring-ring focus-visible:ring-0"
              placeholder="search for anything"
            />
            <div className="md:flex items-center px-2.5 gap-0.5 py-1 rounded shadow-[0px_6px_15px_1px_rgba(109,109,109,0.15)] hidden">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 5V15C12.5 15.4945 12.6466 15.9778 12.9213 16.3889C13.196 16.8001 13.5865 17.1205 14.0433 17.3097C14.5001 17.4989 15.0028 17.5484 15.4877 17.452C15.9727 17.3555 16.4181 17.1174 16.7678 16.7678C17.1174 16.4181 17.3555 15.9727 17.452 15.4877C17.5484 15.0028 17.4989 14.5001 17.3097 14.0433C17.1205 13.5865 16.8001 13.196 16.3889 12.9213C15.9778 12.6466 15.4945 12.5 15 12.5H5C4.50555 12.5 4.0222 12.6466 3.61108 12.9213C3.19995 13.196 2.87952 13.5865 2.6903 14.0433C2.50108 14.5001 2.45157 15.0028 2.54804 15.4877C2.6445 15.9727 2.8826 16.4181 3.23223 16.7678C3.58187 17.1174 4.02732 17.3555 4.51228 17.452C4.99723 17.5484 5.49989 17.4989 5.95671 17.3097C6.41353 17.1205 6.80397 16.8001 7.07868 16.3889C7.35338 15.9778 7.5 15.4945 7.5 15V5C7.5 4.50555 7.35338 4.0222 7.07868 3.61108C6.80397 3.19995 6.41353 2.87952 5.95671 2.6903C5.49989 2.50108 4.99723 2.45157 4.51228 2.54804C4.02732 2.6445 3.58187 2.8826 3.23223 3.23223C2.8826 3.58187 2.6445 4.02732 2.54804 4.51228C2.45157 4.99723 2.50108 5.49989 2.6903 5.95671C2.87952 6.41353 3.19995 6.80397 3.61108 7.07868C4.0222 7.35338 4.50555 7.5 5 7.5H15C15.4945 7.5 15.9778 7.35338 16.3889 7.07868C16.8001 6.80397 17.1205 6.41353 17.3097 5.95671C17.4989 5.49989 17.5484 4.99723 17.452 4.51228C17.3555 4.02732 17.1174 3.58187 16.7678 3.23223C16.4181 2.8826 15.9727 2.6445 15.4877 2.54804C15.0028 2.45157 14.5001 2.50108 14.0433 2.6903C13.5865 2.87952 13.196 3.19995 12.9213 3.61108C12.6466 4.0222 12.5 4.50555 12.5 5Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <p className="text-black/50">K</p>
            </div>
          </div>
        </div>
        <button className="xl:hidden" onClick={() => openNavigation()}>
          <Menu />
        </button>

        <div className="flex items-center space-x-6">
          <button onClick={() => dispatch(setComingSoonModalOpen(true))}>
            <Book />
          </button>
          <button onClick={() => dispatch(setComingSoonModalOpen(true))}>
            <Bell />
          </button>
          <button
            onClick={() => dispatch(setComingSoonModalOpen(true))}
            className="w-6 h-6 xl:w-10 xl:h-10"
          >
            <img src={Status} alt="status" className="w-6 h-6 xl:w-10 xl:h-10" />
          </button>
        </div>
      </div>

      <AnimatePresence>
        {!mobileSideBarIsCollapsed && (
          <motion.div
            initial={{ x: -1000 }}
            animate={{ opacity: 1, scale: 1, x: 1 }}
            exit={{
              opacity: 0,
              scale: 0.95,
              x: -1000,
              transition: {
                duration: 0.5,
                ease: 'linear',
              },
            }}
            transition={{
              duration: 0.5,
              delay: 0.1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
            className="xl:hidden bg-white h-full w-full min-h-screen absolute top-0 z-20"
          >
            <div className="bg-side-bar-gradient xl:hidden h-full">
              <div className="bg-[#FAFAFA4D] backdrop-blur-[150px] h-full p-8 flex flex-col space-y-10 ">
                <div className="flex justify-between">
                  <img src={FullLogo} alt="logo" className="h-8" />
                  <button>
                    <X size={30} onClick={closeNavigation} />
                  </button>
                </div>

                <div className="h-full flex flex-col justify-between">
                  <div className="space-y-2">
                    <MobileSidebarLink icon={<Accounts />} route={ACCOUNTS_ROUTE} text="Accounts" />
                    <MobileSidebarLink icon={<Cards />} route={CARDS_ROUTE} text="Cards" />
                    <MobileSidebarLink icon={<Refill />} route={REFILL_ROUTE} text="Refill" />
                    <MobileSidebarLink icon={<Vault />} route={VAULT_ROUTE} text="Vaults" />
                  </div>

                  <div className="space-y-2">
                    <MobileSidebarLink
                      icon={<Transactions />}
                      route={TRANSACTIONS_ROUTE}
                      text="Transactions"
                    />
                    <MobileSidebarLink comingSoon icon={<Lifestyle />} route="#" text="Lifestyle" />
                  </div>

                  <div className="space-y-2">
                    <MobileSidebarLink icon={<Settings />} route={SETTINGS_ROUTE} text="Settings" />
                    {/* <MobileSidebarLink comingSoon icon={<Support />} route="#" text="Support" /> */}
                    {/* <MobileLogoutLink route={LOGOUT_ROUTE} text="Sign out" /> */}
                    <button onClick={() => navigate(ACCOUNT_DETAILS_ROUTE)} className="w-full">
                      {!isPending && data ? (
                        <div className="border w-full rounded-lg py-3 flex px-3 justify-between bg-white">
                          <Avatar
                            avatar={data.data.preference.avatar}
                            className="w-8 h-8 my-auto"
                          />
                          <div className="text-start">
                            <p className="text-xs">{truncateText(fullName, 20)}</p>
                            <p className="text-[0.625rem] text-grey-text">
                              {truncateText(data.data.email, 30)}
                            </p>
                          </div>
                          <ChevronRight size={20} />
                        </div>
                      ) : (
                        <Skeleton className="h-[3.538rem]" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavigationBar;
